import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import HVAC from './Pages/HVAC';
import Heating from './Pages/Heating';
import AirConditioning from './Pages/AirConditioning';
import About from './Pages/About';
// import NotFound from './Pages/NotFound'
import PlumbingResidential from './Pages/PlumbingResidential';
import PlumbingCommercial from './Pages/PlumbingCommercial';
import { Route, Routes } from "react-router-dom";
// import React, { useState } from "react";

function App() {

  return (
   
    <>
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/plumbing-residential' element={<PlumbingResidential />} />
        <Route path='/plumbing-commercial' element={<PlumbingCommercial />} />
        <Route path='/about' element={<About />} />
        <Route path='/hvac' element={<HVAC />} />
        <Route path='/heating' element={<Heating />} />
        <Route path='/air-conditioning' element={<AirConditioning />} />
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
      <Footer></Footer>

    </>
    
  );
}


export default App;
