import './PlumbingResidential.css';
import waterDist from "../Images/water-dist-icon.svg";
import drainage from "../Images/drainage-icon.svg";
import waterHeater from "../Images/water-heater-icon.svg";
import sewer from "../Images/sewer-icon.svg";

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';


function PlumbingResendential() {
    useEffect(() => {
        seo.setMetaTitle("Residential Plumbing Services in Seattle | A-Game");
        seo.setMetaDescription("Residential plumbing services in Seattle are something that no homeowner can go without. When you're in need of plumbing assistance, reach out to A-Game.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <div>
            {/* RESIDENTIAL PLUMBING HERO IMAGE */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container py-6 HatOnly'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>Residential Plumbing Service in Seattle</h1>
                    </div>
                    <p className='fw-bold fontborder-dark' style={{color: 'white'}}>Here at A-Game Plumbing And Heating, we're committed to providing quality solutions to our customers in the greater Seattle area. As installers, we have a combined 50+ years of industry experience working in residential and commercial settings. We understand plumbing can be stressful, and we do our very best to relieve that burden with our great service, knowledge, experience, and, most importantly, our trustworthy technicians. We offer residential & commercial plumbing services to meet your needs. Satisfaction guaranteed!</p>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>

            <div className='spacer'></div>

            {/* SERVICES */}
            <div className='container py-6'>
                
                <h1 className='text-center pb-5'>How A-Game plumbing can help you.</h1>

                <div className='row text-center px-3'>
                    <p>Experience unparalleled residential plumbing services in Seattle with A-Game Plumbing and Heating. As local homeowners ourselves, we understand the importance of reliable plumbing solutions tailored to the unique needs of Seattle residents. Our team of certified professionals is dedicated to delivering exceptional workmanship and the highest level of customer satisfaction.</p>
                    <p>Whether it's routine maintenance, sewer system repairs, or full-scale installations, we bring our A-game to ensure your home's plumbing and heating systems operate flawlessly. We can even repair or replace hot water heaters if needed. With our proactive approach and meticulous attention to detail, we not only solve your current issues but also safeguard your home against future problems. Choose A-Game Plumbing and Heating—where we're proud to offer the best residential plumbing services in Seattle.</p>
                </div>

                <div className='row text-center px-3' style={{color: 'white'}}>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="waterDist " src={waterDist} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Water Distribution</h3>
                            <p className='fs-5'>We are able to service and install all components required for the proper operation of your water distribution system including: Valves, Piping, Shower Systems, Water Heaters, Faucets and other plumbing related appliances.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="drainage" src={drainage} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Sanitary Drainage</h3>
                            <p className='fs-5'>We can assist you in the installation and maintenance of your home's drainage system. This includes: under sink tubular and garbage disposal, main trunk lines and branch drains, and even adding a new bathroom entirely!</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="waterHeater" src={waterHeater} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Water Heaters</h3>
                            <p className='fs-5'>We are well-versed in the installation and maintenance of Water Heating Systems. We can assess the needs of your home and repair or replace all residential water heaters whether it be a tank, tankless or heat pump water heater.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="sewer" src={sewer} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Sewer Services</h3>
                            <p className='fs-5'>We have the ability to assist you in the maintenance or replacement of your homes sewer system, and can provide you with drain cleaning, scope imaging, and trenched or trenchless repair options.</p>
                        </div>
             
                    </div>
                </div>
            </div>

        </div>
    )

};

export default PlumbingResendential