import './AirConditioning.css';

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';

function AirConditioning() {
    useEffect(() => {
        seo.setMetaTitle("Air Conditioning Services in Seattle | A-Game");
        seo.setMetaDescription("Having access to cool air is essential when summer comes around. Utilize our air conditioning services in Seattle to prepare your system for hotter weather.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <>
            {/* AirConditioning Hero Image */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container AirConditioning py-6'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>Air Conditioning Services in Seattle</h1>
                    </div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>
        
            {/* Page Content */}
            <div className="spacer"></div>
            <div className='text-center container py-6'>
                <h1 className='pb-5'>Air Conditioning Services in Seattle</h1>
                <p>If you want to make sure your home or business is ready for summer, you need to partner with A-Game Plumbing and Heating—your ultimate partner for comprehensive air conditioning services in Seattle. No matter whether you need minor repairs or a complete installation, our expert team is equipped to provide efficient, reliable solutions that fit your unique needs. From installations and repairs to routine air conditioning maintenance, we guarantee high-quality work that keeps your home or business cool and comfortable.</p>
                <p>At A-Game Plumbing and Heating, we prioritize your satisfaction, offering personalized service that understands and resolves your air conditioning repair challenges. With years of experience serving the greater Seattle area community, trust us to bring professionalism, speed, and precision to every job, ensuring you stay cool when it matters most. Partner with us today and experience unparalleled air conditioning service in Seattle that keeps you, your home, and your business at the perfect temperature.</p>
            </div>
            <div className="spacer"></div>
        </>
    );
}

export default AirConditioning;