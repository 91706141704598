import { React, useRef} from "react";
import './Navbar.css';
import IconXSm from "../Images/Icon-xsml.png";
import { Link } from 'react-router-dom';

function Navbar() {

    const navButton = useRef(null);
    const linksContainerRef = useRef(null);

    function collapseNav() {
        navButton.current.classList.add("collapsed");
        linksContainerRef.current.classList.remove("show");
    }

    return (

        <nav className="navbar navbar-expand-lg sticky-top navbar-dark" style={{backgroundColor: "black"}}>
            <div className="container">
                <Link onClick={collapseNav} className="navbar-brand mx-3" to="/">
                    <img className="IconXSm " src={IconXSm}  alt="Home" to="/"></img>
                </Link>
                <button 
                    ref={navButton}
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarContent" 
                    aria-controls="navbarSupportedContent" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div ref={linksContainerRef} className="collapse navbar-collapse text-end" id="navbarContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link onClick={collapseNav} className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-link dropdown">
                            <div className="nav-item dropdown-toggle" data-bs-toggle="dropdown" to='#' role="button" aria-expanded="false">Plumbing Services</div>
                            <ul className="dropdown-menu">
                                <li>
                                    <div className="dropdown-item">
                                        <Link onClick={collapseNav} className="nav-link" style={{color: 'black'}} to="/plumbing-residential">Residential</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown-item">
                                        <Link onClick={collapseNav} className="nav-link" style={{color: 'black'}} to="/plumbing-commercial">Commercial</Link>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-link dropdown">
                            <div className="nav-item dropdown-toggle" data-bs-toggle="dropdown" to='#' role="button" aria-expanded="false">HVAC Services</div>
                            <ul className="dropdown-menu">
                                <li>
                                    <div className="dropdown-item">
                                    <li className="nav-item">
                                        <Link onClick={collapseNav} className="nav-link" style={{color: 'black'}} to="/hvac">HVAC</Link>
                                    </li>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown-item">
                                        <Link onClick={collapseNav} className="nav-link" style={{color: 'black'}} to="/heating">Heating</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown-item">
                                        <Link onClick={collapseNav} className="nav-link" style={{color: 'black'}} to="/air-conditioning">Air Conditioning</Link>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link onClick={collapseNav} className="nav-link" to="/about">About Us</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-none d-lg-block">
                        <li className="nav-item fw-semibold fs-5">
                            <a className='' style={{color: "#E91D25"}} href='tel:2064767295'>(206) 476-7295</a>
                            
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container d-flex justify-content-center d-lg-none">
                <a className='fw-semibold fs-5' style={{color: "#E91D25"}} href='tel:2064767295'>(206) 476-7295</a>
            </div>
        </nav>

    )

};

export default Navbar