import './About.css';
// import toolbox from "../Images/toolbox-icon.svg";
// import retrofit from "../Images/retrofit-icon.svg";
// import fan from "../Images/fan-icon.svg";

function About() {

    return (
        <div>
            {/* HEATING HERO IMAGE */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container Owners py-6'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>About Us</h1>
                    </div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>

            <div className='spacer'></div>

            {/* Our Mission and Why We Care Section */}
            <div className="container-fluid">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="row">
                                <h4 className="">Our Mission</h4>
                                <span>
                                    Our mission is to make sure your home’s plumbing and HVAC systems 
                                    are ready for when you need it. Our team is comprised of like-minded 
                                    professionals who believe in bringing their “A-Game” day-in day-out.  
                                    A-Game prides itself on being locally owned and operated, providing 
                                    the community with the same service that we would provide to our families.
                                </span>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-6">
                            <div className="row">
                                <h4 className="">Our Promise</h4>
                                <span className="align-text-top">
                                    Did a pipe burst and dump water all over? Is sewage backing up into your 
                                    shower? Is your home freezing cold in the dead of winter or scorching hot 
                                    in the peak of summer? Just give us a call – No matter what, A-Game got 
                                    you covered.
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='spacer'></div>

            {/* Motivation Banner Section */}
            <div className="container-fluid" style={{backgroundColor: "#E91D25"}}>
                <div className="row py-5">

                    <div className="col-md-3 " style={{minHeight: "200"}}>
                        <div className="">
                            <h1>Motivation</h1>
                        </div>
                    </div>

                    <div className="col-md-9" style={{minHeight: "200"}}>
                        <div className="">
                            <p className="fw-bold">
                                Did a pipe burst and dump water all over? Is sewage backing up into 
                                your shower? Is your home freezing cold in the dead of winter or 
                                scorching hot in the peak of summer? Just give us a call – No matter 
                                what, A-Game got you covered.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='spacer'></div>

            {/* Our Mission and Why We Care Section */}
            <div className="container-fluid">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="row">
                                <h4 className="">Trusted & Reliable Professionals</h4>
                                <span>
                                We strive to exceed expectations by complete sewer line replacement, 
                                A-Game Plumbing & Heating offers a wide range of residential and commercial 
                                plumbing services to meet your needs. We continually strive to exceed 
                                expectations by ensuring that each client receives the very best service.
                                </span>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-4">
                            <div className="row">
                                <h4 className="">Individualized Plumbing Options</h4>
                                <span className="align-text-top">
                                We strive to exceed expectations by ensuring that all plumbing work is 
                                completed to the highest standards. We will provide you with a consultation 
                                to explain all of your available options so that you can choose the scope of 
                                work that’s right for your home or business.
                                </span>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="row">
                                <h4 className="">Satisfaction Guaranteed</h4>
                                <span className="align-text-top">
                                We serve both residential and commercial clients and offer a variety of 
                                money-saving solutions. We combine our use of the highest quality parts and 
                                equipment, and our dedication to delivering exceptional work to provide you 
                                with satisfactory service.
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='spacer'></div>

        </div>
    )

};

export default About
