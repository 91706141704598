import './PlumbingCommercial.css';
import maintenance from "../Images/maintenance-icon.svg";
import tenant from "../Images/tenant-icon.svg";
import genPlumbing from "../Images/gen-plumbing-icon.svg";

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';


function PlumbingCommercial() {
    useEffect(() => {
        seo.setMetaTitle("Commercial Plumbing Services in Seattle | A-Game");
        seo.setMetaDescription("When you need commercial plumbing service in Seattle, A-Game Plumbing and Heating is your unparalleled choice. We handle all jobs, whether big or small.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <div>
            {/* RESIDENTIAL PLUMBING HERO IMAGE */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container Van py-6'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>Commercial Plumbing Services in Seattle</h1>
                    </div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>

            <div className='spacer'></div>

            {/* SERVICES */}
            <div className='container py-6'>
                
                <h1 className='text-center pb-5'>Looking for commercial services? We've got you covered.</h1>

                <div className='row text-center px-3'>
                    <p>When it comes to ensuring that your plumbing systems runs seamlessly, A-Game Plumbing and Heating is your unparalleled choice. We know how crucial uninterrupted operations are to you, which is why we bring our A-game to every commercial plumbing service in Seattle. We can even take care of minor commercial drain repairs. No matter the job, our expert team of licensed professionals is equipped with the latest technology to deliver efficient, reliable services that keep your business moving without a hitch.</p>
                    <p>From routine plumbing maintenance to emergency repairs, we're dedicated to providing prompt, top-notch service that meets your unique needs. Trust A-Game Plumbing and Heating to be your steadfast partner so that you can focus more on what you do best. Reach out today to experience the difference our commitment to excellence and customer satisfaction can make for your business. Learn more about our commercial plumbing services in Seattle below.</p>
                </div>

                <div className='row text-center px-3' style={{color: 'white'}}>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="maintenance " src={maintenance} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Routine Maintenance</h3>
                            <p className='fs-5'>We understand that our commercial customers in the greater Seattle area have a more significant need for routine maintenance in their facilities. We offer drain cleaning, scoping for inspection, and service plans that will fit your needs and your budget while assisting you in identifying issues before they arise.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="tenant" src={tenant} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Tenant Improvement</h3>
                            <p className='fs-5'>Tenant Improvement New commercial customers often require renovation of their space to conduct Business. At A-Game Plumbing and Heating, we are prepared and able to design and install plumbing systems for your business in a timely manner and keep your down-time to a minimum.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#00467F, #1382C7)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="genPlumbing" src={genPlumbing} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>General Plumbing</h3>
                            <p className='fs-5'>Let's face it, nobody knows when plumbing problems will occur and to a business, the down time caused by these issues can be quite an inconvenience.  Any repair or service options available in a residential capacity are offered for commercial properties as well from leaking pipes to clogged drains and new fixture installs.</p>
                        </div>
             
                    </div>
                    
                </div>

            </div>

        </div>
    )

};

export default PlumbingCommercial