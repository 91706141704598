import './HVAC.css';
import toolbox from "../Images/toolbox-icon.svg";
import retrofit from "../Images/retrofit-icon.svg";
import fan from "../Images/fan-icon.svg";

function HVAC() {

    return (
        <div>
            {/* HEATING HERO IMAGE */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container Heating py-6'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>Heating, Ventilation, and Air Condition (HVAC) Services</h1>
                    </div>
                    <p className='fw-bold fontborder-dark' style={{color: 'white'}}>Our homes in the Pacific Northwest are subject to various weather conditions, and we are prepared to provide you the services required to keep your home comfortable. With our reliable professionals utilizing high quality equipment, we guarantee your satisfaction. Call Today!</p>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>

            <div className='spacer'></div>

            {/* SERVICES */}
            <div className='container py-6'>
                
                <h1 className='text-center pb-5'>A-Game heating and cooling solutions year-round.</h1>

                <div className='row text-center px-3' style={{color: 'white'}}>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="toolbox " src={toolbox} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Maintenance</h3>
                            <p className='fs-5'>The proper operation of our home’s heating and air conditioning systems require annual maintenance and inspection to remain reliable. We are able to provide our customers with a comprehensive assessment of their home’s HVAC systems and keep them running smoothly for years to come.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="retrofit" src={retrofit} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>Retrofit</h3>
                            <p className='fs-5'>No matter how well a system was installed there will come a time that it will be in need of replacement. We will be able to appropriately size a replacement system for you and modify your existing system to ensure proper long-term performance.</p>
                        </div>
             
                    </div>
                    <div className='col-xl col-lg-12 me-3 my-3 lrgRounded' style={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)"}}>
                        <div className='row justify-content-center py-5'>
                            <img className="fan" src={fan} style={{height: '70px'}} alt=""></img>
                        </div>
                        <div className='row pb-5'>
                            <h3 className='fw-bold'>New Installation</h3>
                            <p className='fs-5'>Are you considering converting your home’s HVAC system? Do the summers seem hotter than before? No matter your situation, we are here to help and can offer you modern comfort solutions.</p>
                        </div>
             
                    </div>
                    
                </div>
            </div>

        </div>
    )

};

export default HVAC
