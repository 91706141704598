import './Home.css';
import { Link } from 'react-router-dom';
import gpsIcon from "../Images/gps-icon.svg";
import expertIcon from "../Images/expert-icon.svg";
import protectIcon from "../Images/protect-icon.svg";
import starIcon from "../Images/star-icon.svg";
import quoteIcon from "../Images/quote-icon.svg";
import molly from "../Images/testimonials/Molly.png";
import darkcloud from "../Images/testimonials/Darkcloud.png";
import dean from "../Images/testimonials/Dean.png";
import erin from "../Images/testimonials/Erin.png";
import russell from "../Images/testimonials/Russell.png";
import stars from "../Images/testimonials/5star.png";

import { useEffect, useState } from 'react';
import seo from '../Lib/Seo.js';


function Home() {
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userMessage, setUserMessage] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState('false')

    let formSubmit = () => {
        const forms = document.querySelectorAll('.requires-validation')
        Array.from(forms)
        .forEach( (form) => {
            form.addEventListener('submit', (event) => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
                form.classList.add('was-validated')
            }
            if (form.checkValidity()) {
                event.preventDefault()
                fetch('https://4p9s7bfzxl.execute-api.us-west-2.amazonaws.com/sendemail', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
           
                       },
                        body: JSON.stringify({
                            name: userName,
                            email: userEmail,
                            message: userMessage
                       })
                   }).then( (response) => {
                        if (response.status === 200) {
                            setHasSubmitted('true')
                        } else {
                            setHasSubmitted('error')
                        }

                   } )
            }
            }, false)
           
        })

    }

    useEffect(() => {
        seo.setMetaTitle("Plumbing and HVAC Services in the Seattle Area");
        seo.setMetaDescription("When your home or business needs plumbing and HVAC services in the Seattle area, you call A-Game. We're here to help you every step of the way.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <div>
            {/* HERO IMAGE */}
            <div className="container-fluid " style={{backgroundColor: 'black'}}>

                <div className="container text-end p-5 hero">
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md '>
                            <h1 className='fw-bold fontborder-dark' style={{color: '#E91D25'}}>A-GAME PLUMBING & HEATING</h1>
                            <h3 className="fw-bold fontborder-dark" style={{color: 'white'}}>Bring Your A-Game!</h3>
                            {/* <p className='text-light'>superior service and quality to every plumbing and heating project</p> */}
                            <div className="pt-5">

                                {/* GET QUOTE MODAL */}
                                <div className="modal fade" id="quoteModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="quoteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fw-bold fs-4" id="quoteModalLabel">Free quote</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            
                                            <div className="modal-body text-start">
                                                <div className='row py-4'>
                                                    <div className='col'>
                                                        <p className='fs-5'>Call or text is quickest! </p>
                                                    </div>
                                                    <div className='col'>
                                                        <a href='tel:2064767295'>
                                                            <button type="button" className='btn btn-primary'>(206) 476-7295</button>
                                                        </a>
                                                    </div>
                                                </div>
                                                
                                                <div>
                                                    {
                                                        hasSubmitted === 'true' 

                                                    ? 
                                                    
                                                        <div className='container' style={{color: 'green'}}>
                                                            <div className='row h4'>Message sent!</div>
                                                            <div className='row'>We typically respond within 24 hours.</div> 
                                                        </div>
                                                    : 

                                                        hasSubmitted === 'false' 
                                                        
                                                        ?
                                                            <div>
                                                                <p className='fs-5'>OR</p>
                                                        
                                                                <div className='row pt-4'>
                                                                    <p className='fs-5'>Complete the contact form below</p>
                                                                </div>
                                                                
                                                                <form className='container py-4 lrgRounded requires-validation' noValidate style={{backgroundColor: "lightgray"}}>
                                                                    {/* NAME INPUT */}
                                                                    <div className="mb-3">
                                                                        <label htmlFor="nameInput" className="form-label">Name</label>
                                                                        <input 
                                                                            required
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            id="nameInput" 
                                                                            value={userName} 
                                                                            onChange={(event) => {
                                                                                setUserName(event.target.value);
                                                                            }}
                                                                        />
                                                                        <div className="valid-feedback" />
                                                                        <div className="invalid-feedback">
                                                                            Please provide a name along with your message.
                                                                        </div>
                                                                    </div>
                                                                    {/* EMAIL INPUT */}
                                                                    <div className="mb-3">
                                                                        <label htmlFor="emailInput" className="form-label">Email address</label>
                                                                        {/* <div className="input-group has-validation"> */}
                                                                        <div className="input-group">
                                                                            <input 
                                                                                type="email" 
                                                                                className="form-control" 
                                                                                id="emailInput" 
                                                                                placeholder="name@example.com"
                                                                                value={userEmail} 
                                                                                onChange={(event) => {
                                                                                    setUserEmail(event.target.value);
                                                                                }}
                                                                                required
                                                                            />
                                                                            <div className="valid-feedback" />
                                                                            <div className="invalid-feedback">
                                                                                Please provide a valid email address! This is how we will respond to you with quote information.
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* MESSAGE INPUT */}
                                                                    <div className="mb-3">
                                                                        <label htmlFor="messageInput" className="form-label">Message</label>
                                                                        <textarea
                                                                            type='text'
                                                                            className="form-control" 
                                                                            id="messageInput" 
                                                                            rows="3"
                                                                            value={userMessage}
                                                                            onChange={(event) => {
                                                                                setUserMessage(event.target.value);
                                                                            }}
                                                                            required 
                                                                        />
                                                                        <div className="valid-feedback" />
                                                                        <div className="invalid-feedback">
                                                                            Please describe your project or issue here!
                                                                        </div>
                                                                    </div>

                                                                    {/* SUBMIT BUTTON */}
                                                                    <div className='text-center'>
                                                                        <button type="submit" className="btn btn-primary" data-dismiss = "modal" onClick={formSubmit}>Submit</button>
                                                                    </div>

                                                                </form>

                                                            </div>
                                                        
                                                        :

                                                        <div className='container text-center' style={{color: 'orange'}}>
                                                            <div className='row h4'>Uh no, an error occured during submission.</div>
                                                            <div className='row' >We're looking into it. In the meantime, please feel free to email us your inquiry at lance@agameplumbing.com</div> 
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* BUTTON TRIGGER MODAL */}
                                <div className='d-flex justify-content-end'>

                                    <button type="button" className="btn btn-primary fs-5 m-1" data-bs-toggle="modal" data-bs-target="#quoteModal">
                                        <div className='nav-link'>Free quote</div>
                                    </button>
                                    <button type="button" className="btn btn-outline-primary fs-5 m-1" style={{backgroundColor: '#0D6EFD', color: 'white'}} data-bs-target="#quoteModal">
                                        <Link className="nav-link" to="/about">Learn more</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>


            </div>

            <div className='spacer'></div>

            {/* INFOGRAPHIC */}
            <div className='container text-center px-3'>
                <h1 className='pb-5'>Plumbing and HVAC Services in the Seattle Area</h1>
                <p>Welcome to A-Game Plumbing & Heating, your trusted partner for top-notch plumbing and HVAC services in the Seattle area. Our skilled team specializes in offering reliable, efficient services for both homeowners and businesses, ensuring your property is comfortable year-round. Whether you're facing a leaking faucet or in need of comprehensive heating system maintenance, we have the expertise to get the job done right.</p>
                <p>As a highly rated HVAC and plumbing company, we pride ourselves on our commitment to quality, customer satisfaction, and quick response times to all emergencies. With A-Game Plumbing and Heating, you can rest assured that you're not just getting qualified heating and cooling contractors; you're getting partners dedicated to keeping your space running smoothly. Let us help you stay comfortable, no matter the season.</p>
            </div>
            <div className="container-fluid">
                
                <div className="container">
                    <div className='row'>
                        <div className='col-md col-sm-12 text-center me-3 my-3'>
                            <img className="gpsIcon pt-3" src={gpsIcon} width='50px' alt=""></img>
                            <h5 className='pt-3'>Locally Owned.</h5>
                            <span>
                                Most of the A-Game team was born and raised in the Pacific Northwest. We proudly provide plumbing and HVAC services throughout the greater Seattle area, including North Seattle, the East Side, and even Snohomish County.
                            </span>
                        </div>

                        <div className='col-md col-sm-12  text-center me-3 my-3' >
                            <img className="expertIcon pt-3" src={expertIcon} width='55px' alt=""></img>
                            <h5 className='pt-3'>50+ Years Experience.</h5>
                            <span>
                                As installers, we have a combined 50+ years of industry experience working in 
                                residential and commercial settings. We understand plumbing can be stressful 
                                and we do our very best to relieve that burden with our great service, 
                                knowledge and experience, and most importantly, our trustworthy technicians. 
                            </span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md col-sm-12 text-center me-3 my-3'>
                            <img className="starIcon pt-3" src={starIcon} width='50px' alt=""></img>
                            <h5 className='pt-3'>Highly Rated.</h5>
                            <span>
                                We know ratings aren't everything, but there is nothing like the sense of satisfaction, 
                                motivation, and accomplisment when we hear the positive feedback from our customers. It's 
                                an honor to serve such amazing communities. 
                            </span>
                        </div>

                        <div className='col-md col-sm-12  text-center me-3 my-3'>
                            <img className="protectIcon pt-3" src={protectIcon} width='50px' alt=""></img>
                            <h5 className='pt-3'>Licensed. Bonded. Insured.</h5>
                            <span>
                                Plumbing and heating projects can get complicated in a hurry. There are important 
                                factors to know and to consider in order to protect yourself and your home, especially 
                                in Washington State. A-Game technicians know all the relevant policies and 
                                have all the appropraite licenses to protect our customers on every project.
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className='spacer'></div>

            {/* COUPON */}
            <div className='container lrgRounded p-5' style={{backgroundColor: "#FBE9E9"}}>
                <div className='row'>
                    <div className='col-md-3 col-sm-12 text-center mb-2'>
                        <div className='lrgRounded border border-danger text-danger' style={{backgroundColor: "white"}}>
                            <span className='fs-1 fw-bold'>$50 OFF</span>
                            <p className='fs-5'>any plumbing service</p>
                            <p className='fst-italic'>Coupon must be presented at time of service. One-time use.</p>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col m-auto'>
                        <h2>A-Game Plumbing and Heating</h2>
                        <span>Examples of plumbing services include, but are not limited to, the following:</span>
                        <ul>
                            <li>
                                Water Line Repair
                            </li>
                            <li>
                                Sewer Repair
                            </li>
                            <li>
                                Drain Line Cleanings
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-12 d-flex align-items-center'>
                        <button type="button" className="btn btn-outline-primary">
                            <Link className="nav-link" to="/plumbing-residential">see plumbing services</Link>
                        </button>
                    </div>

                </div>
            </div>

            <div className='spacer'></div>

            {/* TESTIMONIALS */}
            <h1 className='text-center pb-5'>Customer Reviews</h1>
            <div className='container-fluid py-5 pipeWall'>
                <div className='container lrgRounded' style={{maxWidth:'650px', height: '550px', backgroundColor: 'white'}} >
                    <div id="carouselTestimonials" className="carousel carousel-dark slide" data-bs-ride='carousel'>
                        <div className='carousel-inner row mx-auto' >
                            <div className="carousel-item active" data-bs-interval="2000">
                                <div className='row p-3'>
                                    <img className='quoteIcon' src={quoteIcon} style={{width: '70px'}} alt=''></img>
                                </div>
                                <div className='row justify-content-center pb-2'>
                                    <img className='stars' src={stars} style={{width: '200px'}} alt=''></img>
                                </div>
                                <p className='px-3' style={{minHeight: '250px'}}>
                                    I highly recommend A-game plumbing! Lance and his crew are courteous, 
                                    professional and quick. They went above and beyond fitting us in quickly 
                                    in serious emergency situations. I completely trust them to give an 
                                    honest opinion. They definitely tried to fix our situation with the most 
                                    affordable, yet effective way possible. Shop no further. You found your 
                                    plumber.
                                </p>
                                <div className='row d-flex justify-content-center text-center'>
                                    <img className='molly' src={molly} style={{width: '70px' }}  alt='slide1'></img>
                                    <p className='fw-bold'>Molly Drumm</p>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">
                                <div className='row p-3'>
                                    <img className='quoteIcon' src={quoteIcon} style={{width: '70px'}} alt=''></img>
                                </div>
                                <div className='row justify-content-center pb-2'>
                                    <img className='stars' src={stars} style={{width: '200px'}} alt=''></img>
                                </div>
                                <p className='px-3' style={{minHeight: '250px'}}>
                                    A-Game installed a bathtub for us and did great work! The quote came fast and 
                                    was reasonable and thorough, we got on the schedule two days later and the 
                                    work was done in a day. It was great working with them!
                                </p>
                                <div className='row d-flex justify-content-center text-center'>
                                    <img className='erin' src={erin} style={{width: '70px' }}  alt='slide2'></img>
                                    <p className='fw-bold'>Erin McDonald</p>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">
                                <div className='row p-3'>
                                    <img className='quoteIcon' src={quoteIcon} style={{width: '70px'}} alt=''></img>
                                </div>
                                <div className='row justify-content-center pb-2'>
                                    <img className='stars' src={stars} style={{width: '200px'}} alt=''></img>
                                </div>
                                <p className='px-3' style={{minHeight: '250px'}}>
                                    I had laundry room work.  They relocated my washer drain and supply, removed 
                                    old galvanized pipe, and plumbed for a new washroom sink. Prompt, reliable, 
                                    good communicators and did great work.  Will be using them for all my future 
                                    plumbing needs.
                                </p>
                                <div className='row d-flex justify-content-center text-center'>
                                    <img className='dean' src={dean} style={{width: '70px' }}  alt='slide3'></img>
                                    <p className='fw-bold'>Dean Heisey</p>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">
                                <div className='row p-3'>
                                    <img className='quoteIcon' src={quoteIcon} style={{width: '70px'}} alt=''></img>
                                </div>
                                <div className='row justify-content-center pb-2'>
                                    <img className='stars' src={stars} style={{width: '200px'}} alt=''></img>
                                </div>
                                <p className='px-3 mx-3' style={{minHeight: '250px'}}>
                                    Fixed my main line blockage very quickly. Thank you so much!
                                </p>
                                <div className='row d-flex justify-content-center text-center'>
                                    <img className='russell' src={russell} style={{width: '70px' }}  alt='slide4'></img>
                                    <p className='fw-bold'>Russell Case</p>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">
                                <div className='row p-3'>
                                    <img className='quoteIcon' src={quoteIcon} style={{width: '70px'}} alt=''></img>
                                </div>
                                <div className='row justify-content-center pb-2'>
                                    <img className='stars' src={stars} style={{width: '200px'}} alt=''></img>
                                </div>
                                <p className='px-3 mx-3' style={{minHeight: '250px'}}>
                                    Some of the best plumbers I've ever found. Would highly recommend.
                                </p>
                                <div className='row d-flex justify-content-center text-center'>
                                    <img className='darkcloud' src={darkcloud} style={{width: '70px' }}  alt='slide5'></img>
                                    <p className='fw-bold'>Darkcloud</p>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselTestimonials" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselTestimonials" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        </button>
                        <div className='spacer'></div>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        </div>
                    </div>
                </div>
            </div>            
    

        </div>
       
    )

};

export default Home