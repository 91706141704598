import './Heating.css';

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';

function Heating() {
    useEffect(() => {
        seo.setMetaTitle("Heating Services in Seattle | Furnace Cleaning in Seattle");
        seo.setMetaDescription("It's time for a better level of quality in heating. Reach out to our team for expert heating services in Seattle. We'll make sure you're warm this winter.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <div>
            {/* Heating Hero Image */}
            <div className="container-fluid" style={{backgroundColor: 'black'}}>
                <div className='container Heating py-6'>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='row'>
                        <h1 className='fw-bold fontborder-dark' style={{color: 'white'}}>Heating Services in Seattle</h1>
                    </div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </div>
            </div>
            
            {/* Page Content */}
            <div className="spacer"></div>
            <div className='text-center container py-6'>
                <h1 className='pb-5'>Heating Services in Seattle</h1>
                <p>At A-Game Plumbing and Heating, we understand the importance of a reliable heating system, especially during Seattle's chilly and damp months. That's why we pride ourselves on offering top-notch heating services in Seattle tailored for both homeowners and businesses. Our team of experts dedicates themselves to ensuring your comfort and safety with efficient, high-quality furnace repair and cleaning solutions.</p>
                <p>With our profound understanding of the greater Seattle area's unique climate, we're committed to delivering warmth and tranquility to your space. Choosing us means opting for peace of mind, knowing that your heating needs are handled with the utmost professionalism and care. Experience the difference of a reliable heating service in Seattle designed around you. Let A-Game Plumbing and Heating bring stellar warmth and comfort back into your life.</p>
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default Heating;