import React from "react";
import fb from "../Images/fb-icon.png";
import gmail from "../Images/gmail-icon.png";
import email from "../Images/email-icon.png";
import { Link } from 'react-router-dom';

function Footer() {

    return (
        <div className="container-fluid py-5" style={{backgroundColor: "#536879"}}>
            <footer className="container py-5">
                <div className="row">
                    <div className="col-sm-12 col-md-4 text-center text-sm-start fs-5" style={{color: "black"}}>
                        {/* <img className="logo" src={logo} alt="Home" to="/"></img>  */}
                        <p>A-Game Plumbing and Heating</p>
                        <p>Open Monday-Friday 8AM–6PM.</p>
                        <p>Copyright 2023. All rights reserved.</p>

                    </div>
                    <div className="col-sm-12 col-md-4 text-center text-light fs-5">
                        <ul className="navbar-nav mb-2 mb-lg-0 mx-4 mx-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/plumbing-residential">Residential Plumbing Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/plumbing-commercial">Commercial Plumbing Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/hvac">HVAC Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">About Us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-4 text-center">
                        <div className="">
                            <Link className="mx-1" to="https://www.facebook.com/AGamePros">
                                <img className='fb' src={fb} alt=''></img>
                            </Link>

                            <Link className="mx-1" to="https://goo.gl/maps/j2BNz3gsYQEEGbz46">
                                <img className='gmail' src={gmail} alt=''></img>
                            </Link>

                            <Link className="mx-1" to="mailto:agameplumbingandheating@gmail.com">
                                <img className='email' src={email} alt=''></img>
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div> 
    )

};

export default Footer